import React from 'react';
import translate from "../../i18n/translate";
import customerStore from '../../store/customerStore';

const PolicyHolder = () => {
    return (
        <>
            <h5 className="card-heading">{translate('ordersummary.policyInfo')}</h5>
            <div className="card">
                <div className="card-body">
                    <div className="row form-group">
                        <div className="col-sm-4 summaryLable">{translate('ordersummary.policyInfo.name')}</div>
                        <div className="col-sm-8 summaryValue">{customerStore.customerFirstName}</div>
                    </div>
                    <div className="row form-group">
                        <div className="col-sm-4 summaryLable">{translate('ordersummary.policyInfo.surname')}</div>
                        <div className="col-sm-8 summaryValue">{customerStore.customerLastName}</div>
                    </div>
                    <div className="row form-group">
                        <div className="col-sm-4 summaryLable">{translate('ordersummary.policyInfo.companyName')}</div>
                        <div className="col-sm-8 summaryValue">{customerStore.companyName}</div>
                    </div>
                    <div className="row form-group">
                        <div className="col-sm-4 summaryLable">{translate('ordersummary.policyInfo.email')}</div>
                        <div className="col-sm-8 summaryValue">{customerStore.customerEmail}</div>
                    </div>
                    <div className="row form-group">
                        <div className="col-sm-4 summaryLable">{translate('ordersummary.policyInfo.sContactNo')}</div>
                        <div className="col-sm-8 summaryValue">{customerStore.customerSecondaryPhoneNo}</div>
                    </div>
                    <div className="row form-group">
                        <div className="col-sm-4 summaryLable">{translate('ordersummary.policyInfo.street')}</div>
                        <div className="col-sm-8 summaryValue">{customerStore.street1}{customerStore.street2 && ', '+customerStore.street2}</div>
                    </div>
                    <div className="row form-group">
                        <div className="col-sm-4 summaryLable">{translate('ordersummary.policyInfo.postalCode')}</div>
                        <div className="col-sm-8 summaryValue">{customerStore.zipCode}</div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4 summaryLable">{translate('ordersummary.policyInfo.city')}</div>
                        <div className="col-sm-8 summaryValue">{customerStore.city}</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PolicyHolder;